import * as React from "react"

import { Input, Textarea, Select, Box, Paragraph } from "theme-ui"

import { useState } from "react"

import headingImage from "../images/img10.svg"
import {
  PaddedContent,
  PageHeading,
  PageWrapper,
} from "../components/modern/layout"
import { SimpleButton } from "../components/modern/buttons"

const FormItem: React.FC<{
  label: string
  children: React.ReactNode
}> = ({ label, children }) => (
  <Box
    sx={{
      marginY: `2.5em`,
      fontSize: `1.6em`,
    }}
  >
    <Paragraph
      sx={{
        marginY: `.8em`,
        fontWeight: `bold`,
      }}
    >
      {label}
    </Paragraph>
    {children}
  </Box>
)

const ContactPage = () => {
  const [messageSent, setMessageSent] = useState(false)
  const [formMessage, setFormMessage] = useState(``)

  function submitForm(e, onMessageSent) {
    e.preventDefault()

    // Create the new request
    const portalid = `1907998`
    const formid = `2899e599-a211-48fb-a012-302441f855a3`
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalid}/${formid}`
    const form = document.querySelector(`#contactform`)
    const formData = new FormData(form)
    const unwantedFields = [`bot-field`, `form-name`] // these fields are added for netlify
    const entries = Array.from(formData.entries())
    const data = {
      submittedAt: Date.now().toString(),
      fields: entries
        .filter(pair => {
          return !unwantedFields.includes(pair[0])
        })
        .map(pair => {
          return { name: pair[0], value: pair[1] }
        }),
    }

    const xhr = new XMLHttpRequest()
    xhr.open(`POST`, url)
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader(`Content-Type`, `application/json`)

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4 && xhr.status == 200) {
        setFormMessage(
          `Thank you for reaching out, we will soon reply to your request.`
        )
        onMessageSent()
      } else if (xhr.readyState == 4 && xhr.status == 400) {
        setFormMessage(`Please fill in a valid email address and a message.`)
        console.log(xhr.responseText) // Returns a 400 error if the submission is rejected.
      } else if (xhr.readyState == 4 && xhr.status == 403) {
        setFormMessage(`No connection to our systems, please try later.`)
        console.log(xhr.responseText) // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState == 4 && xhr.status == 404) {
        setFormMessage(`No connection to our systems, please try later.`)
        console.log(xhr.responseText) // Returns a 404 error if the formGuid isn't found
      }
    }

    xhr.send(JSON.stringify(data))
  }

  return (
    <PageWrapper title="Contact us" pathname="/contact">
      <PageHeading title="Contact us" imageSrc={headingImage}></PageHeading>
      <PaddedContent background="yellow">
        <Box
          sx={{
            maxWidth: `40em`,
          }}
        >
          <form
            id="contactform"
            name="contact"
            data-netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={e => {
              if (!messageSent) submitForm(e, () => setMessageSent(true))
              else {
                e.preventDefault()
                setFormMessage(
                  `A message has been already sent. Please refresh the page if you want to send a new message`
                )
              }
            }}
          >
            <Box>
              <FormItem label="First Name*">
                <Input
                  name="firstname"
                  type="text"
                  placeholder="First name"
                  sx={{ border: `none` }}
                />
              </FormItem>
              <FormItem label="Last Name*">
                <Input
                  name="lastname"
                  type="text"
                  placeholder="Last name"
                  sx={{ border: `none` }}
                />
              </FormItem>
              <FormItem label="Email*">
                <Input
                  name="email"
                  type="text"
                  placeholder="myemail@example.com"
                  sx={{ border: `none` }}
                />
              </FormItem>
              <FormItem label="Company Name*">
                <Input
                  name="company"
                  type="text"
                  placeholder="Acme, Inc."
                  sx={{ border: `none` }}
                />
              </FormItem>
              <FormItem label="Industry">
                <Input
                  name="industry"
                  type="text"
                  placeholder="Technology, Healthcare, etc."
                  sx={{ border: `none` }}
                />
              </FormItem>
              <FormItem label="What can we do for you?*">
                <Select
                  name="what_can_we_do_for_you"
                  defaultValue={`"Please select"`}
                  arrow={() => null}
                  options
                >
                  <option value="">Please select</option>
                  <option value="Product design and development">
                    App modernization
                  </option>
                  <option value="Atlassian support">Atlassian</option>
                  <option value="Cloud migration">Cloud migration</option>
                  <option value="Enterprise customer experience">
                    Customer experience
                  </option>
                  <option value="Product Development">
                    Product development
                  </option>
                  <option value="Product strategy">Product strategy</option>
                  <option value="Security">Security</option>
                  <option value="Other">Other</option>
                </Select>
              </FormItem>
              <FormItem label="Anything else we should know?">
                <Textarea
                  name="message"
                  placeholder="Write your message"
                  sx={{ border: `none` }}
                />
              </FormItem>
            </Box>
            <Box>
              <SimpleButton
                submit={true}
                label="Send message"
                url={`#`}
                theme={{
                  backgroundColor: `black`,
                  textColor: `white`,
                  size: `big`,
                }}
              />
              <Paragraph
                sx={{
                  fontWeight: `bold`,
                  fontSize: `1.3em`,
                  color: `red`,
                  marginY: `1em`,
                }}
              >
                {formMessage}
              </Paragraph>
            </Box>
          </form>
          <Box
            sx={{
              marginY: `5em`,
            }}
          >
            <Paragraph
              sx={{
                fontSize: `1.6em`,
                fontWeight: `bold`,
              }}
            >
              Or send a direct email to our team
            </Paragraph>
            <SimpleButton
              label="hello@tweag.io"
              icomoonIcon=""
              url="mailto:hello@tweag.io"
            />
          </Box>
        </Box>
      </PaddedContent>
    </PageWrapper>
  )
}

export default ContactPage
